<template>
  <div class="Jepaas-shop">
    <div class="box_top">
      <Header :is-login-or-register="isLoginOrRegister" />
    </div>
    <div class="je-content">
      <el-tabs
        v-model="activeName"
        :tab-position="'left'"
        @tab-click="handleClick"
      >
        <el-tab-pane name="SYZS">
          <span slot="label">
            <div class="menuName">商业证书</div>
            <div class="menuExplain">购买商业证书</div>
          </span>
          <!-- 商业证书面板 -->
          <div class="zsPanel">
            <div class="Je-banner">
              <img v-lazy="syzs">
            </div>
            <el-button
              @click="goDetail"
              class="buyBtn"
              type="danger"
            >
              立即购买
              <i class="el-icon-d-arrow-right el-icon--right" />
            </el-button>
            <el-button
              @click="goStrategy"
              type="text"
              class="buyBtnStrategy buyBtn"
            >
              选购攻略
              <!-- <i class="el-icon-d-arrow-right el-icon--right" /> -->
            </el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane
          name="ZQZJ"
          lazy
        >
          <span slot="label">
            <div class="menuName">增强组件</div>
            <div class="menuExplain">JEAPP套件/业务流套件</div>
          </span>
          <!-- 增强组件面板 -->
          <div
            v-if="zjList.imgs.length > 0"
            class="Je-zj-banner"
          >
            <!-- 如果广告位有的话就显示 -->
            <el-carousel
              :autoplay="false"
              class="img_shop"
            >
              <el-carousel-item
                v-for="(img, _img) in zjList.imgs"
                :key="_img"
              >
                <img
                  @click="goUrl(img.values)"
                  :class="img.values.GGGL_CPBM || img.values.GGGL_GGLJ && 'cursor-pointer-hover'"
                  v-lazy="`/jepaas/document/preview?fileKey=${img.values.GGGL_TP}`"
                  class="img_shop"
                >
              </el-carousel-item>
            </el-carousel>
          </div>
          <ModuleList
            :tableDataLoading="tableDataLoading"
            :list="zjList"
            :target="'shop'"
          />
        </el-tab-pane>
        <el-tab-pane
          name="HYCP"
          lazy
        >
          <span slot="label">
            <div class="menuName">行业产品</div>
            <div class="menuExplain">轻云蒜瓣SCRM</div>
          </span>
          <div
            v-if="hycpList.imgs.length > 0"
            class="Je-zj-banner"
          >
            <!-- 如果广告位有的话就显示 -->
            <el-carousel
              :autoplay="false"
              class="img_shop"
            >
              <el-carousel-item
                v-for="(img, _img) in hycpList.imgs"
                :key="_img"
              >
                <img
                  @click="goUrl(img.values)"
                  :class="img.values.GGGL_CPBM || img.values.GGGL_GGLJ && 'cursor-pointer-hover'"
                  v-lazy="`/jepaas/document/preview?fileKey=${img.values.GGGL_TP}`"
                  class="img_shop"
                >
              </el-carousel-item>
            </el-carousel>
          </div>

          <div
            v-if="hycpButtons.length > 0"
            class="margin-top-20 margin-bottom-20 btnHycp"
          >
            <el-button
              @click="hycpBtnClick('all')"
              :class="hycpBtnAll && 'btnChangeTrue' || 'btnChangeFalse'"
              :type="hycpBtnAll && 'danger' || 'text'"
              class="font-size-16"
              style="width: 100px;"
            >
              全部
            </el-button>
            <el-button
              @click="hycpBtnClick('btn', btn, _btn)"
              :class="btn.check && 'btnChangeTrue' || 'btnChangeFalse'"
              v-for="(btn, _btn) in hycpButtons"
              :key="_btn"
              :type="btn.check && 'danger' || 'text'"
              class="buttons_false font-size-16"
              style="width: 100px;"
            >
              {{ btn.name }}
            </el-button>
            <!-- hycpButtons -->
          </div>
          <ModuleList
            :tableDataLoading="tableDataLoading"
            :list="hycpList"
            :showType="true"
            :target="'shop'"
          />
        </el-tab-pane>
        <el-tab-pane
          name="ZYZL"
          lazy
        >
          <span slot="label">
            <div class="menuName">资源资料</div>
            <div class="menuExplain">经典进阶视频/官方文档</div>
          </span>
          <!-- 资源资料面板 -->
          <div
            v-if="zyzlList.imgs.length > 0"
            class="Je-zj-banner"
          >
            如果广告位有的话就显示
            <el-carousel
              :autoplay="false"
              class="img_shop"
            >
              <el-carousel-item
                v-for="(img, _img) in zyzlList.imgs"
                :key="_img"
              >
                <img
                  @click="goUrl(img.values)"
                  :class="img.values.GGGL_CPBM || img.values.GGGL_GGLJ && 'cursor-pointer-hover'"
                  v-lazy="`/jepaas/document/preview?fileKey=${img.values.GGGL_TP}`"
                  class="img_shop"
                >
              </el-carousel-item>
            </el-carousel>
          </div>
          <ModuleList
            :tableDataLoading="tableDataLoading"
            :list="zyzlList"
            :target="'shop'"
          />
        </el-tab-pane>
        <el-tab-pane
          name="RGFW"
          lazy
        >
          <span slot="label">
            <div class="menuName">人工服务</div>
            <div class="menuExplain">VIP服务/专家服务</div>
          </span>
          <!-- 人工服务面板 -->
          <div
            v-if="rgfwList.imgs.length > 0"
            class="Je-zj-banner"
          >
            <!-- 如果广告位有的话就显示 -->
            <el-carousel
              :autoplay="false"
              class="img_shop"
            >
              <el-carousel-item
                v-for="(img, _img) in rgfwList.imgs"
                :key="_img"
              >
                <img
                  @click="goUrl(img.values)"
                  :class="img.values.GGGL_CPBM || img.values.GGGL_GGLJ && 'cursor-pointer-hover'"
                  v-lazy="`/jepaas/document/preview?fileKey=${img.values.GGGL_TP}`"
                  class="img_shop"
                >
              </el-carousel-item>
            </el-carousel>
          </div>
          <ModuleList
            :tableDataLoading="tableDataLoading"
            :list="rgfwList"
            :target="'shop'"
          />
        </el-tab-pane>

        <el-tab-pane
          disabled
          name="DS"
          lazy
        >
          <span slot="label">
            <el-button
              @click="getList('DS')"
              style="width: 100%;background: #f8504e"
              type="danger"
              round
            >欢迎打赏</el-button>
          </span>
        </el-tab-pane>
      </el-tabs>
    </div>
    <OldFloatBut />
    <FloatBut />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer.vue';
import OldFloatBut from '@/components/FloatBut';
import FloatBut from '@/components/newFloatBtn';
import ModuleList from '@/components/moduleList';
import {
  POST_ORDER_TYPE,
} from '@/pages/index/actions/api';
import { getProductList, getAdvertising } from '@/pages/detail/actions/actions';
import { DS_CODE } from '@/pages/detail/actions/utilVarlable';
import { isArray } from '@/util/util';
import syzs from '@/assets/images/syzs.svg';

export default {
  name: 'ShopJepaas',
  components: {
    FloatBut,
    OldFloatBut,
    Header,
    ModuleList,
    Footer,
  },
  data() {
    return {
      tableDataLoading: false, // 自定义加载loading
      hycpBtnAll: true, // 行业产品是否默认选中全部
      hycpButtons: [
      ], // 行业产品的内部筛选
      syzs,
      isLoginOrRegister: '商城',
      isRoutePage: true, // 是否走路由
      activeName: 'SYZS',
      syList: [], // 商业证书商品数据
      banners: [], // 广告位的背景图
      zjList: {
        name: 'ZQZJ',
        list: [],
        imgs: [], // 广告位的图片
      }, // 增强组件的商品信息和列表
      zyzlList: {
        name: 'ZYZL',
        list: [],
        imgs: [], // 广告位的图片
      }, // 资源资料的商品信息和列表
      rgfwList: {
        name: 'RGFW',
        list: [],
        imgs: [], // 广告位的图片
      }, // 资源资料的商品信息和列表
      hycpList: {
        name: 'HYCP',
        list: [],
        imgs: [], // 广告位的图片
      }, // 资源资料的商品信息和列表
    };
  },
  watch: {
    $route: {
      handler(newRouter) {
        // 点击头部单独进入行业产品
        if (this.$route.query && this.$route.query.data == 'CP') {
          this.activeName = 'HYCP';
        } else {
          this.activeName = 'SYZS';
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.$route.params && this.$route.params.act) {
      this.activeName = this.$route.params.act;
    }
    this.getList(this.activeName);
    this.getLoadProducType(); // 进入页面获取行业产品的二级菜单
  },
  methods: {
    // 获取商品类型字典接口
    getLoadProducType() {
      this.$ajax({
        url: POST_ORDER_TYPE,
        data: {
          dictionaryCode: 'JEPAAS_HYFL',
          childCode: 'HYLX',
        },
      }).then((type) => {
        const data = type.data.obj;
        if (type.errorCode) {
          this.hycpButtons = [];
        } else {
          this.hycpButtons = data.map((item) => {
            const btnObj = {
              name: item.DICTIONARYITEM_ITEMNAME || '',
              code: item.DICTIONARYITEM_ITEMCODE || '',
              check: false,
            };
            return btnObj;
          });
        }
      });
    },
    // 行业产品的按钮点击事件
    hycpBtnClick(status, data, _index) {
      this.tableDataLoading = true;
      this.hycpButtons = this.hycpButtons.filter((btn) => {
        btn.check = false;
        return btn;
      });
      if (status == 'all') {
        this.hycpBtnAll = true;
        getProductList({ productType: 'HYCP' }).then((result) => {
          if (result.success) {
            this.hycpList.list = isArray(result.obj) ? result.obj : [];
            this.tableDataLoading = false;
          }
        });
        return;
      }
      this.hycpBtnAll = false;
      this.hycpButtons[_index].check = true;
      getProductList({ productType: 'HYCP', industryType: data.code }).then((result) => {
        if (result.success) {
          this.hycpList.list = isArray(result.obj) ? result.obj : [];
          this.tableDataLoading = false;
        }
      });
    },
    // 切换菜单
    handleClick(tab) {
      this.getList(tab.name);
    },
    // 获取商品列表
    getList(code) {
      let param = {
        productType: '',
      };
      switch (code) {
        // 增强组件
        case 'SYZS':
          param = {
            productType: code,
          };
          break;
        // 增强组件
        case 'ZQZJ':
          param = {
            productType: code,
          };
          break;
        // 资源资料
        case 'ZYZL':
          param = {
            productType: code,
          };
          break;
        // 人工服务
        case 'RGFW':
          param = {
            productType: code,
          };
          break;
        // 行业产品
        case 'HYCP':
          param = {
            productType: code,
          };
          break;
          // 欢迎打赏
        case 'DS':
          window.open(`/shop/${DS_CODE.toLowerCase()}.html`);
          break;
        default:
          break;
      }
      this.getData(param);
    },
    goUrl(url) {
      if (url.GGGL_GGLJ) {
        window.open(url.GGGL_GGLJ);
        return;
      }
      if (url.GGGL_CPBM) {
        window.open(`shop/${url.GGGL_CPBM.toLocaleLowerCase()}.html`);
      }
    },
    // 获取商品数据
    getData(param) {
      this.tableDataLoading = true;
      getProductList(param).then((result) => {
        if (result.success) {
          param.productType == 'SYZS'
            && (this.syList = isArray(result.obj) ? result.obj : []);
          param.productType == 'ZQZJ'
            && (this.zjList.list = isArray(result.obj) ? result.obj : []);
          param.productType == 'ZYZL'
            && (this.zyzlList.list = isArray(result.obj) ? result.obj : []);
          param.productType == 'RGFW'
            && (this.rgfwList.list = isArray(result.obj) ? result.obj : []);
          param.productType == 'HYCP'
            && (this.hycpList.list = isArray(result.obj) ? result.obj : []);
          this.tableDataLoading = false;
        }
      });
      getAdvertising({
        type: param.productType,
        releasePosition: 'JEPAASSC',
      }).then((res) => {
        const imgs = isArray(res.obj || res.rows) ? (res.obj || res.rows) : [];
        if (res.success || imgs.length >= 0) {
          param.productType == 'ZQZJ'
            && (this.zjList.imgs = imgs.filter((item) => {
              item.values.GGGL_TP = this.imgSplit(item.values.GGGL_TP);
              return item;
            }));
          param.productType == 'ZYZL'
            && (this.zyzlList.imgs = imgs.filter((item) => {
              item.values.GGGL_TP = this.imgSplit(item.values.GGGL_TP);
              return item;
            }));
          param.productType == 'RGFW'
            && (this.rgfwList.imgs = imgs.filter((item) => {
              item.values.GGGL_TP = this.imgSplit(item.values.GGGL_TP);
              return item;
            }));
          param.productType == 'HYCP'
            && (this.hycpList.imgs = imgs.filter((item) => {
              item.values.GGGL_TP = this.imgSplit(item.values.GGGL_TP);
              return item;
            }));
        }
      });
    },
    // 根据 * 切割key
    imgSplit(img) {
      let keyData = [];
      // eslint-disable-next-line no-const-assign
      keyData = img.split('*');
      return keyData[1];
    },
    // 跳转证书购买详情页
    goDetail() {
      const em = this.syList[0];
      if (em) {
        const code = em.values && em.values.PRODUCT_SPBH.toLocaleLowerCase(); // 商品编码
        const enableTpl = em.values && em.values.PRODUCT_SYMBSM; // 是否启用模板链接
        const url = em.values && em.values.PRODUCT_XXLJ;
        if (enableTpl == 1) {
          window.open(`/shop/${code}.html`);
        } else {
          window.open(url && url.substring(url.lastIndexOf('/') + 1));
        }
      }
    },
    // 跳转选购攻略
    goStrategy() {
      window.open('https://www.bilibili.com/video/BV1oQ4y1N7vV/');
    },
  },
};
</script>
<style lang="less">
@import '../../style/shop/util';
::-webkit-scrollbar {
  display: none;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: rgba(246, 245, 245, 1);
}
  ::-webkit-scrollbar {
  width: 0 !important;
}::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.Jepaas-shop {
  // position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgb(245, 245, 245);
  .btnHycp {
    .el-button {
      padding: 7px 10px;
    }
  }
  .btnChangeTrue {
    color: white;
    background: #f8504e;
    font-weight: 400;
  }
  .btnChangeFalse {
    color: #3f3f3f;
    background: transparent;
  }
  .el-carousel__container {
    height: 240px;
  }
  .img_shop {
    width: 100%;
    height: 240px;
  }
  .el-tabs {
    position: relative;
    min-height: 840px;
  }
  .el-tabs__header {
    height: 100%!important;
    position: absolute;
    z-index: 1;
  }
  .el-tabs__content {
    margin-left: 270px;
  }
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .je-content {
    margin: 0 auto;
    margin-top: 40px;
    width: 1250px;
    min-height: 840px;
    margin-bottom: 20px;
    .el-tabs--left {
      // height: 840px;
      // padding-bottom: 400px;
      .el-tabs__header.is-left {
        margin-right: 40px;
        padding: 20px 0;
        background-color: #fff;
        border-radius: 6px;
        .el-tabs__nav-wrap::after {
          width: 0;
        }
      }
      .el-tabs__active-bar.is-left {
        width: 0;
      }
      .el-tabs__content {
        /* overflow-y: auto;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        background-color: #fff; */
        border-radius: 6px;
        // height: 100%;
        /* .el-tab-pane {
          // height: 100%;
        } */
        .zsPanel {
          background-color: #fff;
          padding: 0;
          // height: 100%;
          .Je-banner {
            font-size: 0;
            height: 840px;
            img {
              width: 100%;
              height: auto;;
            }
          }
          .buyBtn {
            font-size: 20px;
            font-weight: 600;
            width: 200px;
            height: 50px;
            position: absolute;
            top: 250px;
            left: 71px;
            background: linear-gradient(270deg, #7880f6 0%, #50dcff 100%);
            box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            border: 0;
            &:hover {
              transform: translateY(-1px);
              box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.4);
            }
          }
          .buyBtnStrategy {
            font-size: 18px;
            // font-weight: 600;
            width: 120px;
            height: 50px;
            position: absolute;
            top: 250px;
            left: 281px;
            color: #ffffff;
            background: transparent;
            border: 1.5px solid;
          }
          .Je-step {
            margin-top: 40px;
            img {
              width: 100%;
            }
          }
        }
        .Je-zj-banner {
          img {
            width: 100%;
          }
          margin-bottom: 20px;
        }
      }
      .el-tabs__item.is-left {
        text-align: left;
        padding: 20px;
        padding-right: 60px;
        height: initial;
        line-height: normal;
      }
      .el-tabs__item.is-active {
        .menuName {
          color: #f8504e;
        }
      }
      .menuName {
        font-size: 18px;
        font-weight: 600;
        color: rgba(63, 63, 63, 1);
        margin-bottom: 5px;
      }
      .menuExplain {
        font-size: 14px;
        font-weight: 400;
        color: rgba(169, 171, 169, 1);
      }
    }
  }
}
</style>
