<!--
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-13 13:26:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-01 18:27:33
 -->
<template>
  <div
    v-infinite-scroll="load"
    :infinite-scroll-disabled="disabled"
    class="Je-module-list position-relative"
  >
    <el-row :gutter="20">
      <div>
        <el-col
          v-for="(item, index) in filterList"
          :key="index"
          :span="showType && 12 || 8"
        >
          <div
            v-if="item.values.PRODUCT_TPLTYPE_CODE != 'DS'"
            :style="!showType? index < filterList.length - (cssChangeList) && 'padding-bottom: 20px' : index < filterList.length - (twoChangeList) && 'padding-bottom: 20px' || ''"
            @click="openDetail(item)"
            style="cursor: pointer;"
          >
            <el-card
              shadow="hover"
              class="position-relative"
            >
              <img
                v-if="item.filekey"
                :class="showType && 'imageShowType' || 'image'"
                v-lazy="'/jepaas/document/preview?fileKey=' + item.filekey"
              >
              <div class="wenan">
                <div
                  class="top"
                >
                  <span
                    class="name"
                    style="cursor: pointer"
                  >{{ item.values.PRODUCT_SPMC || '暂无名称' }}</span>
                  <span
                    v-if="item.values.PRODUCT_CSXS && showType"
                    class="price"
                  >
                    <el-tag
                      class="tagVideo"
                      type="success"
                    >
                      合作伙伴
                    </el-tag>
                  </span>
                  <span
                    v-if="!item.values.PRODUCT_CSXS && showType"
                    class="price"
                  >
                    <el-tag
                      class="tagVideo"
                      type="warning"
                    >
                      官方
                    </el-tag>
                  </span>
                  <span
                    v-if="item.values.PRODUCT_CKJG > 0 && !showType"
                    class="price"
                  >￥{{ item.values.PRODUCT_CKJG }}</span>
                  <span
                    v-if="item.values.PRODUCT_CKJG == 0 && !showType"
                    class="price"
                  >免费</span>
                </div>

                <div class="bottom">
                  <span class="sm">{{ item.values.PRODUCT_JYSM || '暂无' }}</span>
                  <span class="buybtn">
                    <el-button
                      @click.stop.prevent="openDetail(item)"
                      type="danger"
                      class="background-f8504e"
                      size="mini"
                      round
                    >{{ showType && '立即咨询' || '立即购买' }}</el-button>
                  </span>
                </div>
              </div>
              <img
                v-if="item.values.PRODUCT_ZDZB_CODE"
                v-lazy="zj_logo"
                class="zj-logo"
              >
            </el-card>
          </div>
        </el-col>
      </div>
      <!-- <div
        v-else
        class="padding-left-10"
      >
        <span
          class="cursor-pointer-hover-F9514F"
          @click="noPathClick(false)"
        >{{ list.name || '没得' }}</span>
        <span
          v-if="list.name == 'RGFW'"
          class="padding-left-10 cursor-pointer-hover-F9514F"
          @click="noPathClick('WTPC')"
        >WTPC</span>
      </div> -->
      <el-col :span="24">
        <p
          v-if="loading"
          class="loading"
        >
          加载中...
        </p>
        <!-- <p
          v-if="noMore"
          class="noMore"
        >
          没有更多了
        </p> -->
      </el-col>
    </el-row>
    <div
      v-if="filterList.length == 0 && tableDataLoading == false"
      class="nullDatas"
      style="display:table-cell;vertical-align:middle;text-align: center;"
    >
      <img
        src="../pages/detail/assets/images/alreadyIndent/nullData.png"
        alt
      >
      <div class="color-BEBEBE">
        暂无数据
      </div>
    </div>
  </div>
</template>
<script>
import zj_logo from '@/assets/newHomeImg/home/zj-logo.svg';

export default {
  name: 'Modulelist',
  props: {
    tableDataLoading: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Object,
      default: () => ({}),
    },
    showType: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: 'shop',
    },
  },
  data() {
    return {
      productlist: [],
      zj_logo,
      loading: false,
      cssChangeList: 0, // 当前需要隐藏的下边距
      twoChangeList: 0, // 当是行业产品的时候显示两个
    };
  },
  computed: {
    filterList() {
      return this.productlist.filter(item => item.values.SY_STATUS == '1');
    },
    noMore() {
      return this.productlist.length >= 3;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  watch: {
    list: {
      handler(nv) {
        this.productlist = nv.list;
        let nums = nv.list.length;
        nv.list.forEach((ds, _ds) => {
          if (ds.values.PRODUCT_TPLTYPE_CODE == 'DS') {
            nums = nv.list.length - 1;
          }
          if (ds.values.SY_STATUS != 1) {
            nums -= 1;
          }
        });
        this.cssChangeList = nums - this.getChangeNum(nums); // 因为隐藏掉打赏了 所以要加一
        this.twoChangeList = nums - this.getChangeTwoNum(nums);
        this.productlist.forEach((item) => {
          item.filekey = item.values && item.values.PRODUCT_TB && item.values.PRODUCT_TB.split('*')[1];
        });
      },
      deep: true,
    },
  },
  methods: {
    /**
     * el-image点击跳转导致body滚动条消失的解决
     * 参考：https://www.jianshu.com/p/e55fb3dc7081
     */
    closePreview(values) {
      const img = this.$refs[`img_${values.PRODUCT_SPBH}`][0];
      // 点击图片，关闭预览
      img.closeViewer();
    },
    getChangeNum(num) {
      if ((num - (parseInt(num / 3) * 3)) == 0) {
        return num - 3;
      }
      return (parseInt(num / 3) * 3);
    },
    getChangeTwoNum(num) {
      if ((num - (parseInt(num / 2) * 2)) == 0) {
        return num - 2;
      }
      return (parseInt(num / 2) * 2);
    },
    // 测试的跳转详情
    noPathClick(name) {
      switch (name || this.list.name) {
        case 'ZYZL':
          window.open('shop/zyzl_1.html');
          break;
        case 'RGFW':
          window.open('shop/vip_1.html');
          break;
        case 'WTPC':
          window.open('shop/wtpc_1.html');
          break;
      }
    },
    // 跳转组件详情
    openDetail(em) {
      const code = em.values.PRODUCT_SPBH.toLocaleLowerCase(); // 商品编码
      const enableTpl = em.values.PRODUCT_SYMBSM || 1; // 是否启用模板链接
      const url = em.values.PRODUCT_XXLJ;
      // if (this.target == 'shop') {
      //   window.open(url);
      // } else {
      //   window.open(url.substring(url.lastIndexOf('/') + 1));
      // }
      // 如果是链接地址的话，优先跳转链接地址
      if (em.values.PRODUCT_YESORNOMK_CODE == 1) {
        window.open(em.values.PRODUCT_LJDZ);
        return;
      }
      if (enableTpl == 1) {
        window.open(`/shop/${code}.html`);
      } else {
        window.open(url.substring(url.lastIndexOf('/') + 1));
      }
    },
    /**
     * @name: load
     * @Descripttion: 加载数据
     * @param {}
     * @return: null
     */
    load() {
      if (this.activeName != 'SYZS') {
        /* this.loading = true;
        const data = [
          {
            id: 10,
            name: '加载数据',
            url: require('@/assets/imgs/about/imgth_1.png'),
            sm: 50,
            price: 10000,
          },
        ];
        setTimeout(() => {
          this.productlist = this.productlist.concat(data);
          this.loading = false;
        }, 1000); */
      }
    },
  },
};
</script>
<style lang="less" scoped>
  @import "../pages/index/style/shop/util";
</style>
<style lang="less">
.Je-module-list {
  min-height: 500px;
  .nullDatas {
    width: 1000px;
    padding-top: 200px;
  }
  .tagVideo {
    width: 90px;
    text-align: center;
    height: 25px;
    line-height: 25px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .zj-logo {
    position: absolute;
    top: 0;
    right: 0;
  }
  .el-card__body {
    padding: 8px 8px 0 8px;
  }
  .el-card {
    &:hover {
      transform: translateY(-6px);
    }
  }
  .loading,
  .noMore {
    text-align: center;
    margin: 0;
    color: #888;
    font-size: 16px;
  }
  .image {
    width: 100%;
    height: 160px;
    border-radius: 8px;
  }
  .imageShowType {
    height: 240px;
    width: 100%;
    border-radius: 8px;
  }
  .wenan {
    padding: 10px;
    .top,
    .bottom {
      padding-top: 5px;
      display: flex;
      align-items: center;
    }
    .name {
      font-size: 18px;
      font-weight: 600;
      color: rgba(63, 63, 63, 1);
      flex: 1;
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
    }
    .price {
      font-size: 16px;
      font-weight: 400;
      color: #f8504e;
      text-align: right;
    }
    .sm {
      flex: 1;
      font-size: 13px;
      font-weight: 400;
      color: rgba(63, 63, 63, 1);
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
    }
    .buybtn {
      .el-button {
        width: 90px;
        text-align: center;
        font-size: 10px;
        /*  background:linear-gradient(90deg,rgba(248,77,78,1) 0%,rgba(252,98,80,1) 100%); */
      }
    }
  }
}
</style>
